import React from "react"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"
import TopHeader from "../../../components/top-header"

import { Row, Container, Col } from 'react-bootstrap/'
import RetmesReporting from "../../../images/retmes-reporting.svg"
import { useStaticQuery, graphql } from "gatsby"
import { injectIntl, FormattedMessage } from "gatsby-plugin-intl"
import Img from "gatsby-image"

const Raporlama = ({intl})  => {
  const data = useStaticQuery(graphql`
  query {
    headerImage: file(relativePath: { eq: "modules/Analiz.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`)
  return <Layout>
    <TopHeader />
    <SEO title={intl.formatMessage({ id: "solutions_table_module_20" })} />
    <Container>

      <div className='image-wrapper'>
        <Img style={{ borderRadius: '1rem' }} alt="retmes izleme ve analiz" fluid={data.headerImage.childImageSharp.fluid} />
        <h2 className='image-wrapper-text'><FormattedMessage id="modules_title2__1" /></h2>
      </div>
      <h1 className='page-title'><FormattedMessage id="solutions_table_module_20" /></h1>
      <Row className='content-page-row'>
        <Col sm={9}>
          <p className='content-page-p'>
            <FormattedMessage id="reporting_services_desc_p" />
          </p>
        </Col>
        <Col sm={3} style={{ textAlign: '-webkit-center', alignSelf: 'center' }}>
          <img src={RetmesReporting} style={{ width: '13rem' }} alt="retmes gerçek zamanlı izleme" />
        </Col>
      </Row>
      <Row style={{ height: '50vh' }}>
      </Row>
    </Container>
  </Layout>
}

export default injectIntl(Raporlama)